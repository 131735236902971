$module: 'questionnaire';

.#{$module}
{
  &__wrap
  {
    padding-top: 30%;
    padding-bottom: 30%;
    height: 100%;
    overflow: scroll;
  }
}