@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "components/questionnaire";
@import "components/question";

html, body
{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  font-family: 'Open Sans', sans-serif;
}

body{
  background-image: url(/images/bg.png);
  -webkit-background-size: cover;
  background-size: cover;
}