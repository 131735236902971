$module: 'question';

.#{$module}
{
  text-align: center;
  margin-bottom: 200px;
  &--active
  {

  }
  &--inactive
  {
    @include opacity(0.4);
    cursor: pointer;
  }

  &__info
  {
    font-size: 22px;
    line-height: 40px;
    color: #BBB8E7;
    margin-bottom: 5em;
  }

  &__title
  {
    font-size: 24px;
    line-height: 45px;
    color: white;
    margin-bottom: 2em;
  }
  
  &__input
  {
    margin-bottom: 5em;
  }
  &__input-text
  {
    min-height: 70px;
    border-radius: 0;

  }
}